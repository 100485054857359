h1 {
  text-align: center;
  color: white;
}
img {
  margin: auto;
  display: block;
  z-index: 0;
  display: block;
  max-width: 100%;
  height: auto;
  width: auto\9; /* ie8 */
}
.gif {
  min-width: 400px;
}
.page {
  margin-top: 30px;
  display: flex;

  flex-direction: column;
  justify-content: center;
}
button {
  font-size: 30px;
  background-color: #25bcca;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 30px;
  cursor: pointer;
  text-align: center;
  max-width: 300px;
  margin: auto;
  margin-top: 100px;
  position: relative;
}
.left-icon {
  position: relative;
  margin: 10px;
  
}
.confettiExplode {
  align-self: center;
  position: absolute;

  z-index: -1;
}
